import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UserInterceptorInterceptor } from './core/interceptors/user/user-interceptor.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorCodesInterceptor } from './core/interceptors/error-codes/error-codes.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot({
      clickIconToClose: true
    }),
    NgbModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptorInterceptor,
      multi: true,
    }
    ,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorCodesInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
